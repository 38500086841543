@use '@angular/material' as mat;
@use 'sass:map';

$frog-green: #4cbd97;
$frog-yellow: #ffd167;
$frog-pink: #e83a86;
$frog-blue: #168aB1;
$frog-dark-blue: #053b4c;

$palette-green: (
        50 : #eaf7f3,
        100 : #c9ebe0,
        200 : #a6decb,
        300 : #82d1b6,
        400 : #67c7a7,
        500 : #4cbd97,
        600 : #45b78f,
        700 : #3cae84,
        800 : #33a67a,
        900 : #249869,
        A100 : #d6ffed,
        A200 : #a3ffd7,
        A400 : #70ffc1,
        A700 : #57ffb6,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$palette-yellow: (
        50 : #fff9ed,
        100 : #fff1d1,
        200 : #ffe8b3,
        300 : #ffdf95,
        400 : #ffd87e,
        500 : #ffd167,
        600 : #ffcc5f,
        700 : #ffc654,
        800 : #ffc04a,
        900 : #ffb539,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #fff4e2,
        A700 : #ffeac9,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$palette-pink: (
        50 : #fce7f0,
        100 : #f8c4db,
        200 : #f49dc3,
        300 : #ef75aa,
        400 : #eb5898,
        500 : #e83a86,
        600 : #e5347e,
        700 : #e22c73,
        800 : #de2569,
        900 : #d81856,
        A100 : #ffffff,
        A200 : #ffd8e3,
        A400 : #ffa5bf,
        A700 : #ff8bad,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$palette-blue: (
        50 : #e3f1f6,
        100 : #b9dce8,
        200 : #8bc5d8,
        300 : #5cadc8,
        400 : #399cbd,
        500 : #168ab1,
        600 : #1382aa,
        700 : #1077a1,
        800 : #0c6d98,
        900 : #065a88,
        A100 : #b5e2ff,
        A200 : #82cdff,
        A400 : #4fb9ff,
        A700 : #36afff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$palette-darkblue:  (
        50 : #e1e7ea,
        100 : #b4c4c9,
        200 : #829da6,
        300 : #507682,
        400 : #2b5867,
        500 : #053b4c,
        600 : #043545,
        700 : #042d3c,
        800 : #032633,
        900 : #011924,
        A100 : #5fbeff,
        A200 : #2ca9ff,
        A400 : #0093f8,
        A700 : #0084df,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

// Imported from Angular _palette.scss file
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$dark-theme-foreground-palette: (
        base:              white,
        divider:           $light-dividers,
        dividers:          $light-dividers,
        disabled:          $light-disabled-text,
        disabled-button:   rgba(white, 0.3),
        disabled-text:     $light-disabled-text,
        elevation:         black,
        hint-text:         $light-disabled-text,
        secondary-text:    $light-secondary-text,
        icon:              white,
        icons:             white,
        text:              white,
        slider-min:        white,
        slider-off:        rgba(white, 0.3),
        slider-off-active: rgba(white, 0.3),
);

$dark-theme-background-palette: (
        status-bar: black,
        app-bar:    map.get(mat.$grey-palette, 800),
        background: map.get($palette-darkblue, 500),
        hover:      rgba(white, 0.04),
        card:       map.get($palette-darkblue, 500),
        dialog:     map.get($palette-darkblue, 500),
        disabled-button: rgba(white, 0.12),
        raised-button: map.get(mat.$grey-palette, 500),
        focused-button: $light-focused,
        selected-button: map.get(mat.$grey-palette, 800),
        selected-disabled-button: map.get(mat.$grey-palette, 500),
        disabled-button-toggle: black,
        unselected-chip: map.get(mat.$grey-palette, 400),
        disabled-list-option: black,
        tooltip: map.get(mat.$grey-palette, 400),
);


$palette-primary: mat.define-palette($palette-green);
$palette-accent: mat.define-palette($palette-pink);
$palette-warn: mat.define-palette(mat.$red-palette);

// Create the material theme object

$color-theme: (
        color: (
                primary: $palette-primary,
                accent: $palette-accent,
                warn: $palette-warn,
                is-dark: true,
                foreground: $dark-theme-foreground-palette,
                background: $dark-theme-background-palette,
        )
);


//$color-theme: mat.define-dark-theme($palette-primary, $palette-accent, $palette-warn);

$color-primary: mat.get-color-from-palette($palette-primary, 500);
$color-primary-light: mat.get-color-from-palette($palette-primary, 400);
$color-primary-extra-light: mat.get-color-from-palette($palette-primary, 300);
$color-primary-dark: mat.get-color-from-palette($palette-primary, 700);

$color-secondary-extra-light: mat.get-color-from-palette($palette-blue, 100);

$color-accent: mat.get-color-from-palette($palette-accent, 500);
$color-accent-light: mat.get-color-from-palette($palette-accent, 400);
$color-accent-extra-light: mat.get-color-from-palette($palette-accent, 200);
$color-accent-dark: mat.get-color-from-palette($palette-accent, 700);

$color-white: white;
$color-text-primary: #eeeeee;
$color-text-primary-light: white;
$color-text-primary-dark: #cccccc;

$color-text-secondary: rgba(255, 255, 255, 0.7);
$color-text-secondary-light: #555555;
$color-subtitle: rgba(0, 0, 0, 0.54);
$color-text-secondary-dark: #eeeeee;

$color-background: map.get($palette-darkblue, 800);
$color-background-light: map.get($palette-darkblue, 500);
$color-background-extra-light: map.get($palette-darkblue, 300);

$color-background-green: map.get($palette-green, 800);
$color-background-pink: map.get($palette-pink, 800);
$color-background-yellow: map.get($palette-yellow, 800);

$color-stroke-extra-light: map.get($palette-blue, A200);

$color-item-hover: mat.get-color-from-palette($palette-darkblue, 400);

$color-danger: map.get($palette-warn, 800);
$color-danger-light: map.get($palette-warn, 200);
