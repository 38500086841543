
body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.dark {
  color: white;
  >h1 {
    color: white;
  }
}

h1 {
  font-size: 1.6em;
}


h2 {
  font-size: 1.4em;
  font-weight: bold;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
