@import 'mixins';
@import 'theme';
@import 'global';


.show-mobile {
  display: none;
}

@media(max-width : 960px) {
  .hide-mobile {
    display: none !important;
  }

  .show-mobile {
    display: block;
  }
}
